import axios from "axios";
import { getApiRoute } from "src/services";
import { useQuery } from "@tanstack/react-query";

// Make an API call to check if there is an image URL associated with the event in the database
export async function getImageUrl({ calendarId, eventId }) {
  let response = { data: null };
  response = await axios.get(
    getApiRoute("image", "GET_IMAGE_URL", {
      calendarId, eventId
    }, {}),
    { withCredentials: true }
  );
  return response.data;
}

export const useGetImageUrl = (
  params = {
    calendarId: null,
    eventId: null,
  },
  options = {
    enabled: true
  }
) => {
  return useQuery(
    [`image-url-${params.calendarId}-${params.eventId}`],
    () => getImageUrl(params),
    {
      ...options,
      // Only retry if there was an error and it was not a 404
      retry: (failureCount, error) => {
        // If the error response is a 404, then don't retry
        if (error.response?.status === 404) {
          return 0;
        }
        return true;
      }
    }
  );
};
