import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Button } from "@mui/material";

function DeleteCalendarDialog({ open, handleClose, handleDelete, deleteText, deleteMessage, setDeleteText }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Confirm deleting your calendar</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your events will be wiped from all your subscribers' calendars. Any Creator Pro subscriptions will also be cancelled. 
          < br />
          < br />
          You cannot undo this action.
        </DialogContentText>
        <TextField
          data-testid="calendar-delete-dialog-input"
          autoFocus
          value={deleteText}
          label="Type DELETE to confirm"
          fullWidth
          variant="standard"
          onChange={(event) => setDeleteText(event.target.value)}
          helperText={deleteMessage ? deleteMessage : ""}
          sx={{ mt: 2 }}
          error
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button data-testid="calendar-delete-dialog-confirm-button" onClick={handleDelete}>Continue</Button>
      </DialogActions>
    </Dialog>
  );
}
  
export default DeleteCalendarDialog;