import { Box, Typography, Icon, CircularProgress, Collapse } from "@mui/material";
import { memo, useMemo } from "react";
import { useTheme } from "@emotion/react";
import { useDimensions } from "src/utils/useDimensions.js";
import RecommendedCalendar from "./event/RecommendedCalendar.js";
import { useIsMounted } from 'src/utils/Utils.js';
import CalendarAddedSuccessfully from "./CalendarAddedSuccessfully.js";
import { useRecommendedCalendars } from "src/utils/useRecommendedCalendars.js";

const ContainerCollapse = ({ children, id, noRecommendedCalendars }) => {
    const isMounted = useIsMounted()
    const mounted = isMounted()
    const theme = useTheme();
    return (
        <Collapse
            id={id}
            in={true}
            {...(mounted ? { timeout: 'auto' } : {})}
        >
            <Box sx={{
                display: "flex", flexDirection: "column", borderTop: `1px solid ${theme.palette.text.tertiary}`,
                ...noRecommendedCalendars ? { borderBottom: `1px solid ${theme.palette.text.tertiary}`, pb: 4.5 } : {}
            }}>
                {children}
            </Box>
        </Collapse>
    )
}


const RecommendedCalendars = ({ sourceCalendars, onSubscribeAction, displayMode = "added" }) => {
    const theme = useTheme();
    const { isMediumScreen } = useDimensions();
    const sourceCalendar = useMemo(() => sourceCalendars[sourceCalendars.length - 1] ?? null, [sourceCalendars]);
    const { recommendedCalendars, isLoading, noRecommendedCalendars } = useRecommendedCalendars({ sourceCalendar });

    if (!sourceCalendar) return null;

    return (
        <>
            <style>{`
        #recommended-calendars + div > #event-card {
          border-top: none;
        }
        #recommended-calendars + div {
          margin-top: 0px;
        }
        #recommended-calendars:first-of-type .MuiBox-root {
          border-top: none;
        }
      `}
            </style>
            <ContainerCollapse id="recommended-calendars" noRecommendedCalendars={noRecommendedCalendars}>
                {displayMode === "added" &&
                    <CalendarAddedSuccessfully sourceCalendar={sourceCalendar} sx={{ mb: !noRecommendedCalendars ? -1 : "-5px" }} />
                }
                {isLoading && (
                    <Box sx={{ display: "flex", flexDirection: "column", height: "304px", width: "100%", justifyContent: "center", alignItems: "center", gap: 1 }}>
                        <CircularProgress thickness={2} />
                        <Typography variant="subtitle2">You might also like...</Typography>
                    </Box>
                )}
                {displayMode === "related" && noRecommendedCalendars && !isLoading && (
                    <Typography variant="subtitle2" sx={{ margin: "24px auto -8px auto" }}>No recommended calendars found.</Typography>

                )}
                {!noRecommendedCalendars && !isLoading && (
                    <Box sx={{
                        display: "flex", flexDirection: "column", py: 4.5, pl: 2,
                        borderBottom: `1px solid ${theme.palette.text.tertiary}`,
                    }}>
                        {displayMode === "related" && (
                            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5, mb: 1 }}>
                                <Icon baseClassName="material-symbols-outlined" sx={{ fontWeight: "300", fontSize: "1.8rem", color: "success.main" }}>check</Icon>
                                <Typography variant="h2" data-testid="related-calendars-title">You might like</Typography>
                            </Box>
                        )
                        }
                        <Box sx={{ display: { xs: "flex", md: "grid" }, alginItems: "center", mt: 1, gap: { xs: 2, md: "24px 16px" }, flexDirection: { xs: "column" }, gridTemplateColumns: { md: "1fr 1fr" } }}>
                            {recommendedCalendars?.map((event, index) => (
                                <>
                                    <RecommendedCalendar
                                        calendar={event.calendar}
                                        key={event.calendarId}
                                        event={event.event}
                                        calendarId={event.calendarId}
                                        showUpload={false}
                                        position={index}
                                        onSubscribeAction={onSubscribeAction}
                                    />
                                    {isMediumScreen && index !== recommendedCalendars.length - 1 && <hr style={{ width: "100%", border: `0.5px solid ${theme.palette.text.tertiary}` }} />}
                                </>
                            ))}
                        </Box>
                    </Box>
                )}
            </ContainerCollapse>
        </>
    )

}

export default memo(RecommendedCalendars);