
import { memo, useContext } from 'react';
import { Button, Box, List, ListItem, ListItemText, ListItemIcon, Icon, Typography, ListItemButton, Select, MenuItem } from "@mui/material";
import CenterDialog from './CenterDialog.js'
import BottomDialog from './BottomDialog.js';
import { useTheme } from "@emotion/react";
import { useTimelineContent } from "src/utils/useTimelineContent.js";
import { DateRangeOutlined, FolderOutlined } from '@mui/icons-material';
import { useDimensions } from "src/utils/useDimensions.js";
import { checkSubscriptionStatus } from "src/utils/Utils.js";
import { AuthContext } from "src/utils/AuthContext.js";

export const CalendarSelect = ({ setSelectedCalendar, user, selectedCalendar, calendars, size = 'sm', sx }) => {
    const theme = useTheme();
    const handleCalendarSelect = async (calendar) => {
        setSelectedCalendar(calendar);
    };

    const CalendarOption = ({ calendar, key }) => {
        const { subscribed } = checkSubscriptionStatus({ user, calendar });
        return (
            <MenuItem key={key} value={calendar._id} disabled={subscribed}>
                {calendar.calendars ? (
                    <FolderOutlined sx={{ fontSize: "1rem", mr: 1 }} /> // Render folder icon for group
                ) : (
                    <DateRangeOutlined sx={{ fontSize: "1rem", mr: 1 }} /> // Render calendar icon for individual calendar
                )}
                <Typography
                    data-testid="calendar-details-name"
                    variant="h5"
                >
                    {calendar.name}
                </Typography>
                {subscribed && (
                    <Icon
                        baseClassName="material-symbols-outlined"
                        sx={{ color: `${theme.palette.success.main}`, verticalAlign: "middle", fontSize: "1rem", ml: 1 }}
                    >bookmark_added</Icon>
                )}
            </MenuItem>
        )
    }

    return (
        <Select
            variant="outlined"
            value={selectedCalendar?._id || ""}
            sx={{
                "& .MuiTypography-root": {
                    fontSize: size === "md" ? `${theme.typography.h2.fontSize} !important` : `${theme.typography.h5.fontSize} !important`,
                    fontWeight: 500,
                },
                ".MuiOutlinedInput-notchedOutline": {
                    display: 'none'
                },
                ".MuiSelect-select": {
                    display: 'flex !important',
                    alignItems: 'center !important',
                    padding: "0px",
                },
                ...sx,
            }}
            onChange={(e) =>
                handleCalendarSelect(
                    calendars.find((calendar) => calendar._id === e.target.value)
                )
            }
        >
            {calendars.map((calendar) => (
                CalendarOption({ calendar, key: calendar._id })
            ))}
        </Select>
    )
}

const AddToCalendarDialog = ({
    open,
    handleClose,
    handleAddToCalendar,
    handleAddToGroup,
    calendarId,
    handle,
    calendars,
    selectedCalendar,
    setSelectedCalendar,
    isEventCard
}) => {

    const { user } = useContext(AuthContext);
    const theme = useTheme();
    const { isSmallScreen } = useDimensions();


    const BottomDialogSx = {
        height: "298px",
        '.MuiDialogContent-root': { padding: "0px" },
        '.MuiDialogTitle-root': { margin: isEventCard ? "0" : "auto" },
    }

    const CenterDialogSx = {
        '.MuiPaper-root': { maxWidth: "393px" },
        '.MuiDialogContent-root': { padding: "0px" },
        '.MuiDialogTitle-root': { margin: isEventCard ? "0" : "auto" },
    }

    const ListItemSx = {
        '.MuiButtonBase-root': {
            alignItems: "flex-start",
        },
        '.MuiListItemIcon-root': {
            marginTop: "1%",
            minWidth: "34px",
        },
    }


    const DialogContent = () => {
        return (
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <ListItem disablePadding sx={ListItemSx}>
                    <ListItemButton data-testid="add-to-calendar-confirm" onClick={() => {
                        handleAddToCalendar(selectedCalendar)
                        handleClose()
                    }} >
                        <ListItemIcon>
                            <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}`, verticalAlign: "middle" }}>
                                calendar_add_on
                            </Icon>
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography variant="h5">Add to Calendar</Typography>
                            }
                            secondary={
                                <Typography variant="subtitle2" sx={{ color: `${theme.palette.text.quaternary}` }}>
                                    Add all upcoming events to your calendar app to keep up with new events and updates.
                                </Typography>
                            } />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding sx={ListItemSx}>
                    <ListItemButton data-testid="add-to-group-confirm" onClick={() => {
                        handleAddToGroup(selectedCalendar)
                        handleClose()
                    }}>
                        <ListItemIcon>
                            <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}`, verticalAlign: "middle" }}>
                                folder
                            </Icon>
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography variant="h5">Add to Group</Typography>
                            }
                            secondary={
                                <Typography variant="subtitle2" sx={{ color: `${theme.palette.text.quaternary}` }}>
                                    Seamlessly merge multiple calendars into one with Groups.
                                </Typography>
                            } />
                    </ListItemButton>
                </ListItem>
            </List>
        )
    }

    return isSmallScreen ? (
        <BottomDialog open={open} handleClose={handleClose} sx={BottomDialogSx} dialogTitle={isEventCard ?
            selectedCalendar?.name
            :
            <CalendarSelect setSelectedCalendar={setSelectedCalendar} user={user} selectedCalendar={selectedCalendar} calendars={calendars} />}
            dividers
        >
            <DialogContent />
        </BottomDialog>
    )
        : (
            <CenterDialog open={open} handleClose={handleClose} sx={CenterDialogSx} dialogTitle={isEventCard ?
                selectedCalendar?.name
                :
                <CalendarSelect setSelectedCalendar={setSelectedCalendar} user={user} selectedCalendar={selectedCalendar} calendars={calendars} />}
                dividers
            >
                <DialogContent />
            </CenterDialog>
        )
}

export const TimelineWrapper = (Children) => {
    const HocComponent = ({ ...props }) => {
        const {
            calendars,
            selectedCalendar,
            setSelectedCalendar,
        } = useTimelineContent({ handle: props.handle, calendarId: props.calendarId, refetch: false });
        const targetCalendar = props.isEventCard ? props.initialCalendar : selectedCalendar
        if (!calendars || !selectedCalendar) return (<></>);
        return <Children {...props} calendars={calendars} selectedCalendar={targetCalendar} setSelectedCalendar={setSelectedCalendar} />
    }

    return HocComponent;
};

export default TimelineWrapper(memo(AddToCalendarDialog, (prevProps, nextProps) => {
    return prevProps.open === nextProps.open
        && prevProps.handle === nextProps.handle
        && prevProps.calendarId === nextProps.calendarId
        && prevProps.selectedCalendar === nextProps.selectedCalendar
}));