import React, { useContext } from "react";
import { Typography, Box, Button, Stack, Icon } from "@mui/material";
import { AuthContext } from "src/utils/AuthContext.js";
import Navbar from "../blocks/Navbar.js";
import axios from "axios";
import { getApiRoute } from "src/services";
import Footer from "../blocks/Footer.js";
import { useTheme } from "@emotion/react";
import { useDimensions } from "src/utils/useDimensions.js";
import { ContainerCloseButton } from "./ContainerCloseButton.js";
import { CommentsList } from "./CommentsList.js";
import User3 from 'src/assets/users/user3.png';
import User4 from 'src/assets/users/user4.png';
import { customEvent } from "src/utils/gtag.js";

function CreatorUpgrade() {
  const user = useContext(AuthContext).user;
  const theme = useTheme();
  const { isSmallScreen } = useDimensions();

  const handleUpgrade = async () => {
    customEvent({
      name: 'upgrade_creator_checkout',
      category: 'Upgrade',
      label: 'Upgrade to Creator Checkout Click',
      data: {
        user_id: user._id,
        user_email: user.email,
        user_handle: user.handle ?? 'no_handle',
      },
    });
    let successUrl = '/import';
    const successUrlSearchParams = new URLSearchParams("");
    successUrlSearchParams.set("success", "true");
    successUrl = `${successUrl}?${successUrlSearchParams.toString()}`
    try {
      const response = await axios.post(
        getApiRoute("upgrade", "STRIPE_CHECKOUT"),
        { type: "creator", successUrl: successUrl },
        { withCredentials: true }
      );
      window.location.href = response.data.url;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Navbar user={user} />
      <Box
        id="container"
        sx={{ margin: "auto", maxWidth: { xs: '100%', sm: "600px" }, position: "relative", p: 2, textAlign: "center" }}
      >
        {isSmallScreen && <ContainerCloseButton />}
        <Icon baseClassName="material-symbols-outlined" sx={{ display: "block", margin: "0 auto", color: `${theme.palette.icon.primary}`, verticalAlign: "middle", fontSize: "3rem", mt: 5, mb: 1, fontWeight: 300 }}>
          editor_choice
        </Icon>
        <Typography variant="title">
          Upgrade to continue.
        </Typography>
        <Typography variant="subtitle2" sx={{ mb: 3, color: "text.secondary" }}>
          Import a new calendar with even more perks.
        </Typography>
        <Stack sx={{ margin: "auto", maxWidth: "360px", textAlign: "center", gap: 1 }}>

          <Box display="flex" flexDirection="column" pl={3} gap={1} mb={3}>
            <Typography variant="body1" sx={{ mb: 1, textAlign: "left" }}>
              <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}`, verticalAlign: "bottom", mr: "10px", fontWeight: 300 }}>
                all_inclusive
              </Icon>
              Unlimited subscribers
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, textAlign: "left" }}>
              <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}`, verticalAlign: "bottom", mr: "10px", fontWeight: 300 }}>
                manage_search
              </Icon>
              Priority in search results
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, textAlign: "left" }}>
              <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}`, verticalAlign: "bottom", mr: "10px", fontWeight: 300 }}>
                code
              </Icon>
              Embed on your website or app
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, textAlign: "left" }}>
              <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}`, verticalAlign: "bottom", mr: "10px", fontWeight: 300 }}>
                video_camera_back
              </Icon>
              Personalize with videos and images
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, textAlign: "left" }}>
              <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}`, verticalAlign: "bottom", mr: "10px", fontWeight: 300 }}>
                support_agent
              </Icon>
              24/5 customer service
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, textAlign: "left" }}>
              <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}`, verticalAlign: "bottom", mr: "10px", fontWeight: 300 }}>
                featured_video
              </Icon>
              Eligible to be featured
            </Typography>
            <Typography variant="body1" sx={{ textAlign: "left" }}>
              <Icon baseClassName="material-symbols-outlined" sx={{ color: `${theme.palette.icon.primary}`, verticalAlign: "bottom", mr: "10px", fontWeight: 300 }}>
                payments
              </Icon>
              Eligible to apply for creator fund
            </Typography>
          </Box>

          <Button
            data-testid="creator-upgrade-button"
            size="shrinked"
            variant="contained"
            color="primary"
            onClick={handleUpgrade}
          >
            Pay $10/Month
          </Button>
          <Typography
            variant="subtitle2"
            sx={{ pt: 0.5, margin: "auto", color: "text.secondary", mb: 2 }}
          >
            Recurring billing. Cancel anytime.
          </Typography>

          <CommentsList comments={[{
            text: "My go-to for seamless event creation! Worth every penny. 💰",
            src: User3,
          }, {
            text: "Embedded into my website effortlessly and loving the subscriber count!",
            src: User4,
          }]} />

          <Typography
            variant="footer"
            sx={{ mt: 4, mb: 1, textAlign: "center", fontSize: "11px", lineHeight: "14.85px" }}
          >
            Your plan starts as soon as you set up payment. We'll renew your plan for you (unless you cancel 24 hours before the end of your billing cycle). You can manage your subscription under Your Plan.
          </Typography>
        </Stack>
      </Box>
      <Footer />
    </div>
  );
}

export default CreatorUpgrade;
