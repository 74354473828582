import { useTheme } from "@emotion/react";
import {
  InputAdornment,
  Paper,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import { useState } from "react";

export const TimeSaveBlock = ({
  handleUpgrade,
  handleGoHome,
  isLifeTimeMember,
}) => {
  const [hours, setHours] = useState(3);
  const [price, setPrice] = useState(50);
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setHours(newValue);
  };

  return (
    <>
      <Paper
        variant="outlined"
        sx={{
          p: "20px 20px 2.5rem 20px",
          border: "none",
          borderRadius: "20px 20px 0 0",
          boxShadow: "0 2px 20px #0000001f",
          background: `${theme.palette.secondary.main}`,
          width: "fit-content",
          mt: 0.5,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            textAlign: "left",
            fontSize: "15px",
            fontWeight: 500,
            color: `#6d6d72`,
          }}
        >
          Hours lost per week on manually adding events to your calendar and tracking event changes
        </Typography>
        <Typography
          variant="h5"
          sx={{
            mt: 2,
            mb: "14px",
            textAlign: "left",
            fontSize: "24px",
            fontWeight: 600,
          }}
        >
          {hours} hours
        </Typography>
        <Slider
          value={hours}
          onChange={handleChange}
          aria-labelledby="continuous-slider"
          getAriaValueText={(value) => `${value} hours`}
          valueLabelDisplay="off"
          step={1}
          sx={{
            color: "rgba(0,0,0,0.87)",
            mb: "40px",
            "& .MuiSlider-track": {
              border: "none",
            },
            "& .MuiSlider-thumb": {
              width: 24,
              height: 24,
              backgroundColor: "#fff",
              "&::before": {
                outline: "solid 2px #000000",
              },
              "&.Mui-focusVisible": {
                boxShadow: "none",
              },
              "&:hover, &.Mui-active": {
                boxShadow: "0 4px 8px rgba(0,0,0,0.4)",
              },
            },
          }}
          min={1}
          max={10}
          marks={[
            { value: 1, label: "1" },
            { value: 10, label: "10" },
          ]}
        />
        <Typography
          variant="body1"
          sx={{
            textAlign: "left",
            fontSize: "15px",
            fontWeight: 500,
            color: `#6d6d72`,
          }}
        >
          How much 1 hour of your time is worth to you
        </Typography>
        <TextField
          value={price}
          variant="outlined"
          size="small"
          onChange={(e) => setPrice(e.target.value)}
          type="number"
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{ "& p": { fontSize: "22px", color: "text.primary" } }}
              >
                $
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ "& p": { fontSize: "22px" } }}
              >
                /hr
              </InputAdornment>
            ),
          }}
          min={1}
          max={100000}
          sx={{
            mt: 1.5,
            background: "#fff",
            width: "100%",
            "& .MuiInputBase-root": {
              height: "56px",
              fontSize: "22px",
              fontWeight: "500",
            },
          }}
        />
      </Paper>

      <Paper
        variant="outlined"
        sx={{
          p: "30px 20px",
          border: "none",
          borderRadius: "20px",
          background: "#141c24",
          width: "fit-content",
          mt: -2,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: "primary.contrastText",
            fontWeight: 600,
            mb: "8px",
            fontSize: "15px",
          }}
        >
          Hours saved per month with Stanza+
        </Typography>
        <Typography
          variant="h2"
          sx={{
            mb: "12px",
            color: "primary.contrastText",
            fontSize: "80px",
            lineHeight: "100px",
            fontWeight: 600,
          }}
        >
          {Math.ceil(hours * 4)} hrs
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            color: "#b3bcca",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20px",
          }}
        >
          Or ${Math.ceil(hours * price * 4 - 2.5)} return on investment
        </Typography>
        <Button
          data-testid="consumer-upgrade-button"
          size="shrinked"
          variant="outlined"
          color="secondary"
          onClick={!isLifeTimeMember ? handleUpgrade : handleGoHome}
          sx={{ width: "100%", mt: 2 }}
        >
          {!isLifeTimeMember ? "Start Free Trial" : "Go home"}
        </Button>
        <Divider sx={{ my: "20px", borderColor: "#ffffff1c !important" }} />

        <Typography
          variant="body1"
          sx={{
            color: "primary.contrastText",
            fontWeight: 600,
            textAlign: "left",
            mb: 1,
            fontSize: "15px",
          }}
        >
          How did we get this number?
        </Typography>
        <TableContainer
          component={(props) => (
            <Paper
              variant="outlined"
              sx={{
                background: "#141c24",
              }}
              {...props}
            />
          )}
          sx={{ mb: 1.5 }}
        >
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ border: "none", p: "0px 0px 8px 0px" }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "primary.contrastText" }}
                  >
                    Hours lost per month
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    minWidth: "65px",
                    border: "none",
                    p: "0px 0px 8px 0px",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "primary.contrastText", fontWeight: 600 }}
                  >
                    {hours * 4} hours
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ border: "none", p: "0px 0px 8px 0px" }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "primary.contrastText" }}
                  >
                    Value of Stanza+ for saving {hours * 4} hours of your time
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ border: "none", p: "0px 0px 8px 0px" }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "primary.contrastText",
                      fontWeight: 600,
                    }}
                  >
                    ${hours * price * 4}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ borderColor: "#ffffff1c", p: "0px 0px 16px 0px" }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "primary.contrastText",
                    }}
                  >
                    Cost of Stanza+ subscription per month
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ borderColor: "#ffffff1c", p: "0px 0px 16px 0px" }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "primary.contrastText", fontWeight: 600 }}
                  >
                    $2.50
                  </Typography>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ border: "none", p: "16px 0px 0px 0px" }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "primary.contrastText",
                      fontSize: "15px",
                      fontWeight: 600,
                    }}
                  >
                    Total ROI per month
                  </Typography>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ border: "none", p: "16px 0px 0px 0px" }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "primary.contrastText", fontWeight: 600 }}
                  >
                    ${Math.ceil(hours * price * 4 - 2.5)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
