import React, { useContext } from "react";
import { Stack, IconButton, Icon, Tooltip, Box, Typography, Link } from "@mui/material";
import { useTheme } from "@emotion/react";
import ShareDialog from "src/components/blocks/dialogs/ShareDialog.js";
import { handleOpenWebcal, getWebcalFormat } from "src/utils/Utils.js";
import { AuthContext } from "src/utils/AuthContext.js";

const CreatorProfileButtons = ({ calendar, handle }) => {
    const theme = useTheme();
    const { user, refetchAuth } = useContext(AuthContext);

    const handleOpenPreview = () => {
        window.open(`/preview/${handle}/${calendar._id}`, '_blank');
    }

    return (
        <Stack direction="row" spacing={2}>
            <ShareDialog calendar={calendar} handle={handle} as="Button" />
            <Tooltip title="Preview">
                <IconButton
                    onClick={handleOpenPreview}
                    size="small"
                >
                    <Icon
                        baseClassName="material-symbols-outlined"
                        fontSize="medium"
                        sx={{ color: `${theme.palette.icon.primary}`, verticalAlign: "middle" }}
                    >visibility</Icon>
                </IconButton>
            </Tooltip>
            {/* resubscribe to own created group */}
            {calendar.calendars && (
                <Tooltip title="Resubscribe">
                <IconButton
                onClick={() => {
                  const groupApiUrl = `${calendar?.stanzaLink}/${user?.id}.ics`;
                  handleOpenWebcal(getWebcalFormat(groupApiUrl), calendar);
                }}
                size="small"
              >
                <Icon
                  baseClassName="material-symbols-outlined"
                  fontSize="small"
                  sx={{
                    color: `${theme.palette.icon.primary}`,
                    verticalAlign: "middle",
                  }}
                >
                  event_repeat
                </Icon>
              </IconButton>
                </Tooltip>
            )}
        </Stack>
    )
}

export default CreatorProfileButtons;