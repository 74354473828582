/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useContext, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Event from "./blocks/Event.js";
import SubscribeButton from "./blocks/SubscribeButton.js";
import Navbar from "./blocks/Navbar.js";
import {
  Typography,
  Select,
  MenuItem,
  Box,
  Alert,
  Tabs,
  Tab,
  Link
} from "@mui/material";
import { FolderOutlined, DateRangeOutlined } from "@mui/icons-material";
import { ProfileUser } from "./blocks/profile/ProfileUser.js";
import ActionCard from "./blocks/ActionCard.js";
import LoadingAnimation from "./blocks/LoadingAnimation.js";
import { AuthContext } from "../utils/AuthContext.js";
import { getPageRoute } from "src/services";
import Footer from "./blocks/Footer.js";
import { useTimelineContent } from "src/utils/useTimelineContent.js";
import { checkSubscriptionStatus, getWebcalFormat, handleOpenWebcal } from "src/utils/Utils.js";
import { useDimensions } from "src/utils/useDimensions.js"
import { useTheme } from "@emotion/react";
import CreatorMailingList from "./blocks/CreatorMailingList.js";
import { revealNavbar } from "src/utils/Utils.js";
import CreatorProfileButtons from "./blocks/CreatorProfileButtons.js";
import { Helmet } from 'react-helmet';

function Timeline({ preview }) {
  const { user } = useContext(AuthContext);
  let { handle, calendarId } = useParams(); // Get the handle parameter from the URL
  const [activeTab, setActiveTab] = useState("upcoming");
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    if (handle) {
      window.scroll({
        top: 0,
        behavior: 'auto'
      })
    }
  }, [handle]);

  if (handle.startsWith('@')) {
    // If handle starts with "@", remove it. For legacy routes - remove in the future
    handle = handle.substring(1);
  }

  const {
    calendars,
    upcomingEvents,
    pastEvents,
    calendarCreator,
    description,
    tags,
    isLoadingEvents,
    initialLoad,
    creatorView,
    selectedCalendar,
    setSelectedCalendar,
    isLoading,
    isLoadingCalendarEvents,
    invalidCreator,
    calendarNotFound,
  } = useTimelineContent({ handle, calendarId });

  const { subscribed } = checkSubscriptionStatus({
    user,
    calendar: selectedCalendar,
  });

  const disabledUpcomingTab = !isLoadingEvents && upcomingEvents?.length === 0;
  const disabledPastTab = !isLoadingEvents && pastEvents?.length === 0;
  const disableAllTabs = disabledUpcomingTab && disabledPastTab;
  const isGroup = Boolean(selectedCalendar?.calendars);
  const showUpload = !preview && !isGroup ? creatorView : false;

  // handles selecting Past or Upcoming events tab.
  const handleTabChange = async (event, key) => {
    revealNavbar();
    setActiveTab(key);
  };

  const { isMediumScreen } = useDimensions();

  const handleCalendarSelect = async (calendar) => {
    setSelectedCalendar(calendar);
  };

  useEffect(() => {
    if (disabledUpcomingTab && !disabledPastTab) {
      setActiveTab("past");
    } else {
      setActiveTab("upcoming");
    }
  }, [disabledUpcomingTab, disabledPastTab]);

  const noUpcomingEventsAlert = useMemo(() => {
    if (!subscribed && user?._id !== calendarCreator?._id) {
      return (
        <>
          There are no upcoming events.{" "}
          <span
            className="a"
            onClick={() =>
              document.getElementById("add-to-calendar-button").click()
            }
          >
            Add to Calendar
          </span>{" "}
          and you'll be the first to know when new events are announced.
        </>
      );
    }

    return (
      <>
        There are no upcoming events. Your calendar will be updated once new
        events are announced.
      </>
    );
  }, [user, calendarCreator, subscribed]);

  // If the creator doesn't exist
  if (!initialLoad && !isLoading && (invalidCreator || calendarNotFound)) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minHeight: "100vh",
        }}
      >
        <div>
          <Navbar user={user} />
          <Box
            sx={{
              margin: "auto",
              marginTop: "0",
              maxWidth: "600px",
              p: 2,
              mb: 10,
            }}
          >
            <ActionCard
              title="No calendars found"
              description="There are no calendars associated with this handle."
              primaryButtonCTA="Discover calendars"
              primaryAction={() => navigate(getPageRoute("home", "HOME"))}
              iconName="calendar_month"
            />
          </Box>
        </div>
        <Footer />
      </div>
    )
  }
  if (initialLoad || isLoading || isLoadingCalendarEvents) {
    return (
      <div>
        <Box sx={{ margin: "auto", maxWidth: { xs: '100%', sm: "600px" }, p: 2, mb: 10 }}>
          <LoadingAnimation />
        </Box>
      </div>
    );
  }

  // Render the sorted events in two tabs - Upcoming & Past
  return (
    <div>
      <Navbar user={user} />
      <Helmet>
          <title>{selectedCalendar?.name} Timeline - Stanza</title>
          <meta name="description" content="View the upcoming and past events of a creator on Stanza. Subscribe to their calendar to get updates on new events." />
      </Helmet>
      <Box sx={{ margin: "auto", maxWidth: { xs: '100%', sm: "600px" }, minHeight: "100vh", p: { xs: 2, md: "1rem 2rem" } }}>
        {preview && (
          <Alert severity="warning" sx={{ my: 3 }}>
            You are in preview mode. This is how your calendar page appears to your fans.
          </Alert>
        )}
        <ProfileUser creator={calendarCreator} viewer={user} creatorView={creatorView} preview={preview} selectedCalendar={selectedCalendar} sx={{ mt: { md: 3 } }} />
        <Select
          variant="outlined"
          value={selectedCalendar?._id || ""}
          onClose={(e) => {
            // force to remove focus of the select which blocks the scroll
            setTimeout(() => document.activeElement.blur(), 100)
          }}
          sx={{
            marginTop: "10px",
            "& .MuiTypography-root": {
              fontSize: '24px',
              fontWeight: 700,
              letterSpacing: "-0.4px",
              textWrap: "pretty"
            },
            ".MuiOutlinedInput-notchedOutline": {
              display: 'none'
            },
            ".MuiSelect-select": {
              display: 'flex',
              alignItems: 'center',
              padding: "8px 32px 8px 0",
            },
            ".MuiSelect-select .MuiSvgIcon-root": {
              display: 'none'
            },
          }}
          onChange={(e) => {
            handleCalendarSelect(
              calendars.find((calendar) => calendar._id === e.target.value)
            )
            // force to remove focus of the select which blocks the scroll
            setTimeout(() => document.activeElement.blur(), 100)
          }}
        >
          {calendars.map((calendar) => (
            <MenuItem key={calendar._id} value={calendar._id}>
              {calendar.calendars ? (
                <FolderOutlined sx={{ fontSize: "1rem", mr: 1 }} /> // Render folder icon for group
              ) : (
                <DateRangeOutlined sx={{ fontSize: "1rem", mr: 1 }} /> // Render calendar icon for individual calendar
              )}
              <Typography
                data-testid="calendar-details-name"
                variant="h5"
              >
                {calendar.name}
              </Typography>
            </MenuItem>
          ))}
        </Select>
        {description && description !== "no description" && (
          <Typography
            data-testid="calendar-details-description"
            variant="body1"
            color="text.secondary"
          >
            {description}
          </Typography>
        )}
        <Box sx={{ mb: 3, mt: "10px" }}>
          {!creatorView || preview ? (
              <SubscribeButton
                creator={calendarCreator}
                calendar={selectedCalendar}
                handle={handle}
                isEventCard={false}
                setSelectedCalendar={setSelectedCalendar}
                onSubscribeAction={(subscribed) => {
                  // Go to the home page if the user is subscribed to the event in timeline.
                  if (subscribed) {
                    localStorage.setItem("subscribed", JSON.stringify(selectedCalendar));
                    navigate(`/`);
                  }
                }}
              />
          ) : <CreatorProfileButtons
            calendar={selectedCalendar}
            handle={handle}
          />}
        </Box>
        {user?._id === calendarCreator?._id && !preview &&
          <CreatorMailingList creator={calendarCreator} />
        }
        {/* <Stack direction="row" sx={{ flexWrap: "wrap", mr: 2, my: 2 }}>
          {tags.map((tag) => (
            <Chip
              data-testid={`calendar-details-tag-${tag}`}
              label={`${tag}`}
              id={`${tag}`}
              key={`${tag}`}
              name={`${tag}`}
              color={"primary"}
              variant={"outlined"}
              size={"small"}
              sx={{ mb: 1, mr: 1 }}
            />
          ))}
        </Stack> */}
        {disableAllTabs && (
          <Alert severity="info" sx={{ mt: 2 }}>
            {noUpcomingEventsAlert}
          </Alert>
        )}
        {!disableAllTabs && (
          <>
            <Tabs id="timeline-tabs" value={activeTab} onChange={handleTabChange} variant={"fullWidth"} >
              <Tab label="Upcoming" value="upcoming" disableRipple disabled={disabledUpcomingTab} />
              <Tab label="Past" value="past" disableRipple disabled={disabledPastTab} />
            </Tabs>

            {!disabledUpcomingTab && (
              <Box role="tabpanel" hidden={activeTab !== 'upcoming'} sx={{ pb: !subscribed ? "121px" : 0 }}>
                {isLoadingCalendarEvents || isLoadingEvents ? (
                  <LoadingAnimation />
                ) : (
                  upcomingEvents.map((upcomingEvent, index) => (
                    <Event
                      calendar={selectedCalendar}
                      key={index}
                      event={upcomingEvent}
                      calendarId={selectedCalendar?._id}
                      showUpload={showUpload}
                      position={index}
                    />
                  ))
                )}
              </Box>
            )}
            {!disabledPastTab && (
              <Box role="tabpanel" hidden={activeTab !== 'past'} sx={{ pb: !subscribed ? "121px" : 0 }}>
                {isLoadingCalendarEvents ||
                  isLoadingEvents ? (
                  <LoadingAnimation />
                ) : (
                  <>
                    {disabledUpcomingTab && (
                      <Alert severity="info" sx={{ mt: 2 }}>
                        {noUpcomingEventsAlert}
                      </Alert>
                    )}
                    {pastEvents.map((pastEvent, index) => (
                      <Event
                        calendar={selectedCalendar}
                        key={index}
                        event={pastEvent}
                        calendarId={selectedCalendar?._id}
                        showUpload={showUpload}
                        position={index}
                        isPastEvent={true}
                      />
                    ))}
                  </>
                )}
              </Box>
            )}
          </>
        )}
      </Box>
    </div>
  );
}

export default Timeline;
