import React, { useState } from "react";
import { Icon, TextField, IconButton, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getPageRoute } from "src/services";

const SearchBar = () => {
    const [message, setMessage] = useState("");
    const navigate = useNavigate();

    const handleSend = () => {
        if (message.trim() !== "") {
            navigate(getPageRoute("chat", "SEARCH"), { state: { term: message } });
        }
    };

    return (
      <>
        <Box sx={{ display: 'flex', pt: 3, mb: 3 }}>
            <TextField
            fullWidth
            type="search"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={(e) => { if (e.key === 'Enter') handleSend(); }}
            placeholder="Search for calendars"
            />
            <IconButton
                variant="contained"
                onClick={handleSend}
                sx={{ ml: "10px", width: "40px", height: "40px" }}
            >
                <Icon fontSize="medium" sx={{ color: 'secondary.main' }} baseClassName="material-symbols-outlined">search</Icon>
            </IconButton>
        </Box>
      </>
    )
}

export default SearchBar;