import ical from "ical.js";
import axios from "axios";
import { getApiRoute } from "src/services";
import { getPageRoute } from "src/services";
import { useRef, useCallback, useEffect } from "react";
import Vibrant from "node-vibrant";
import temporaryEmailsSet from "./temporaryEmails.js";
import * as ICAL from "ical.js";

export const isEmptyCal = (events) =>
  events?.length === 1 &&
  events[0]?.jCal?.[1]
    ?.find((prop) => prop[0] === "description")?.[3]
    .includes(
      `has no upcoming events. Don't worry, as soon as new events are added - your calendar will be automatically updated.`
    );

// Helper function for getUpcomingEvents and getPastEvents
const getDtend = (event, typeHomepage) => {
  const prop = typeHomepage ? event.event.jCal[1] : event.jCal[1];
  const dtendDate =
    prop.find((prop) => prop[0] === "dtend") ||
    prop.find((prop) => prop[0] === "dtstart"); // In cases where events start time / end time is the same, the dtend property is missing. Default to dtstart.
  const dtendValue = dtendDate[3]
    ? ical.Time.fromDateTimeString(
        dtendDate[3].split("T").length > 1
          ? dtendDate[3]
          : dtendDate[3] + "T00:00:00.000Z"
      )
    : ical.Time.fromDateTimeString(dtendDate[0] + "T00:00:00.000Z");
  return dtendValue.toJSDate();
};

export const getUpcomingEvents = (events, count, typeHomepage) => {
  if (isEmptyCal(events)) {
    return [];
  }

  const now = new Date();
  const upcomingEvents = new Map();

  for (const event of events) {
    const dtend = getDtend(event, typeHomepage);
    if (dtend >= now) {
      upcomingEvents.set(event, dtend);
    }
  }

  const sortedEvents = Array.from(upcomingEvents.keys()).sort(
    (event1, event2) => {
      if (upcomingEvents.get(event2) < upcomingEvents.get(event1)) {
        return 1;
      } else if (upcomingEvents.get(event2) > upcomingEvents.get(event1)) {
        return -1;
      }
      return 0;
    }
  );

  return sortedEvents.slice(0, count); // Only include the first {count} upcoming events
};

export const getPastEvents = (events, count) => {
  if (isEmptyCal(events)) {
    return [];
  }

  const now = new Date();
  const pastEvents = new Map();

  for (const event of events) {
    const dtend = getDtend(event, false);
    if (dtend < now) {
      pastEvents.set(event, dtend);
    }
  }

  const sortedEvents = Array.from(pastEvents.keys()).sort((event1, event2) => {
    if (pastEvents.get(event2) > pastEvents.get(event1)) {
      return 1;
    } else if (pastEvents.get(event2) < pastEvents.get(event1)) {
      return -1;
    }
    return 0;
  });

  return sortedEvents.slice(0, count); // Only include the first {count} past events
};

// Helper function to combine different webcals into one
export const fetchCalendarData = async (
  calendars,
  setEvents,
  setIsLoading = () => {}
) => {
  setIsLoading(true);
  try {
    const responses = await Promise.allSettled(
      calendars.map(async (calendar) => {
        const response = await axios.get(
          getApiRoute("calendar", "GET_CALENDAR_EVENTS", {
            link: encodeURIComponent(calendar.stanzaLink),
          })
        );
        return { response, calendar: calendar, calendarId: calendar._id };
      })
    );

    // filter out errors from public webcals that are not available
    const fulfilledResponses = responses.filter(
      (response) => response.status === "fulfilled"
    );
    const events = fulfilledResponses.flatMap((response) => {
      try {
        const jcalData = ical.parse(response.value.response.data);
        const comp = new ical.Component(jcalData);
        const subcomponents = comp.getAllSubcomponents("vevent");
        return subcomponents.map((subcomponent) => ({
          event: subcomponent,
          calendar: response.value.calendar,
          calendarId: response.value.calendarId,
        }));
      } catch (error) {
        console.error(error);
        return [];
      }
    });

    setEvents(events);
    setIsLoading(false);
  } catch (error) {
    console.error(error);
    setEvents([]);
    setIsLoading(false);
  }
};

// Convert the calendars icsFiles to iCal objects and get the events to merge them into one array
export const getEventsFromIcsFiles = async (calendars, setEvents) => {
  let events = [];
  for (const calendar of calendars) {
    try {
      if (!calendar.icsFile || typeof calendar.icsFile !== "string") {
        console.error(`Invalid icsFile for calendar ${calendar.name}`);
        continue;
      }

      const jcalData = ical.parse(calendar.icsFile);
      const comp = new ical.Component(jcalData);
      const subcomponents = comp.getAllSubcomponents("vevent");

      if (!isEmptyCal(subcomponents)) {
        events = [
          ...events,
          ...subcomponents.map((subcomponent) => ({
            event: subcomponent,
            calendar: { ...calendar },
            calendarId: calendar._id,
          })),
        ];
      }
    } catch (error) {
      console.error(`Error processing calendar ${calendar.name}:`, error);
    }
  }
  setEvents(events);
};

// Helper function to redirect a signed out user to the Sign In page
export const redirectSignedOutUser = (
  user,
  navigate,
  extraSearchParams = {}
) => {
  const newSearchParams = new URLSearchParams(window.location.search);
  for (const [key, value] of Object.entries(extraSearchParams)) {
    newSearchParams.set(key, value);
  }
  if (!user) {
    navigate(
      getPageRoute(
        "auth",
        "SIGNIN",
        {},
        {
          forward: window.location.pathname + "?" + newSearchParams.toString(),
        }
      )
    );
    return true; // indicate that a redirected happened
  }
  return false; // indicate that no redirect happened
};

// Helper function to load the Stripe customer portal
export const handleCustomerPortal = async () => {
  try {
    const response = await axios.post(
      getApiRoute("upgrade", "STRIPE_CUSTOMER_PORTAL"),
      {},
      { withCredentials: true }
    );
    window.location.href = response.data.url;
  } catch (error) {
    console.error(error);
  }
};

// Check if the calendar link is valid
export const validateCalendar = async (link) => {
  try {
    const response = await axios.get(
      getApiRoute("calendar", "GET_CALENDAR_EVENTS", {
        link: encodeURIComponent(link),
      }),
      { withCredentials: true }
    );
    if (response.status === 200) {
      console.log("It's a valid calendar link");
      return response;
    }
  } catch (error) {
    //return the error message
    return { error: "Webcal link is not valid or is not a public calendar" };
  }
};

// check if calendar is in a user's createdGroups
export const checkSubscriptionStatus = ({ user, calendar }) => {
  const createdGroupIds = new Set(
    user?.createdGroups?.flatMap((group) => group.calendars) ?? []
  );
  const inGroup = createdGroupIds.has(calendar.id ?? calendar._id);

  const addedGroup = user?.addedGroups?.includes(calendar._id) ?? false; // check if calendar is in a user's addedGroups
  return {
    inGroup,
    addedGroup,
    subscribed: !!(
      user?.addedCalendars?.includes(calendar._id) ||
      inGroup ||
      addedGroup
    ),
  };
};

// Helper function to format a number of subscribers. Set includeText = false if you only want the number
export const formatSubscribers = (subscribers, includeText = true) => {
  if (!subscribers || subscribers < 5) {
    return "";
  }
  if (subscribers === 1) {
    return includeText ? "1 Subscriber" : "1";
  }
  if (subscribers > 1000) {
    const roundedSubscribers = (subscribers / 1000).toFixed(1);
    const formattedSubscribers = roundedSubscribers.endsWith(".0")
      ? Math.round(roundedSubscribers)
      : roundedSubscribers;
    return includeText
      ? `${formattedSubscribers}K Subscribers`
      : `${formattedSubscribers}K`;
  }

  return includeText ? `${subscribers} Subscribers` : `${subscribers}`;
};

export const revealNavbar = (target = "window") => {
  const upgradeBanner = document.getElementById("UpgradeBanner");
  const offset = upgradeBanner ? upgradeBanner.clientHeight : 0;
  const navbar = document.getElementById("AppBar");
  const tabs = document.querySelector(".MuiTabs-root");
  if (navbar.style.opacity === "0") {
    if (target === "search") {
      navbar.style.opacity = "1";
      navbar.style.height = "56px";
      tabs.style.top = `${0 + offset}px`;
      return;
    }
    navbar.style.opacity = "1";
    tabs.style.top = `${48 + offset}px`;
  }
};

// Helper function to sort an array of objects alphabetically
export const sortAlphabetically = (arr, targetKey = null) => {
  return arr.sort((a, b) => {
    if (targetKey) {
      return a[targetKey].localeCompare(b[targetKey]);
    }
    return a.localeCompare(b);
  });
};

export function useIsMounted() {
  const mountedRef = useRef(false);
  const isMounted = useCallback(() => mountedRef.current, []);
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);
  return isMounted;
}

// Helper function to turn https:// link to webcal:// link
export function getWebcalFormat(url) {
  return `webcal://${url.replace(/^https?:\/\//, "")}`;
}

export const extractImageColors = async (imageUrl) => {
  const pallete = await Vibrant.from(imageUrl).getPalette();
  return {
    dark: pallete.DarkVibrant.getHex(),
    vibrant: pallete.Vibrant.getHex(),
    lightVibrant: pallete.LightVibrant.getHex(),
    lightMuted: pallete.LightMuted.getHex(),
    darkMuted: pallete.DarkMuted.getHex(),
  };
};

export const isInvalidEmail = (string) => {
  if (temporaryEmailsSet.has(string.split("@")[1])) {
    return true;
  }
  return (
    new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).test(string) === false ||
    string === ""
  );
};

export const parseDateEvent = (dateStr) => {
  let isAllDayEvent = false;
  let eventParsed = new Date(dateStr);
  // check if date is in format YYYY-MM-DD (full day event) and add timezone offset
  if (new RegExp(/^\d{4}-\d{2}-\d{2}$/).test(dateStr)) {
    isAllDayEvent = true;
    eventParsed = new Date(
      eventParsed.getTime() + eventParsed.getTimezoneOffset() * 60 * 1000
    );
  }
  return { eventParsed, isAllDayEvent };
};

// Format date to display in a more readable format
export const formatDate = (event, snackbarContext) => {
  const {
    setSnackbarOpen,
    setSnackbarMessage,
    setSnackbarSeverity,
    showSnackBar,
  } = snackbarContext;
  try {
    if (!event) {
      return "";
    }
    let { eventParsed, isAllDayEvent } = parseDateEvent(event);
    const currentDate = new Date();
    const tomorrowDate = new Date(currentDate);
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);

    const eventDate = eventParsed.toLocaleDateString("en-US", {
      weekday: "short",
      month: "short",
      day: "numeric",
    });
    const eventTime = eventParsed.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    if (isAllDayEvent) {
      return "All Day";
    } else if (eventParsed.toDateString() === currentDate.toDateString()) {
      return `Today at ${eventTime}`;
    } else if (eventParsed.toDateString() === tomorrowDate.toDateString()) {
      return `Tomorrow at ${eventTime}`;
    } else {
      return `${eventDate} | ${eventTime}`;
    }
  } catch (error) {
    console.error(error);
    showSnackBar(
      setSnackbarOpen,
      setSnackbarMessage,
      setSnackbarSeverity,
      "This browser isn't displaying dates correctly. Please use another browser.",
      "error"
    );
    return "Unknown date/time";
  }
};

// Create the .ics for the user to download an individual event
export const convertToIcs = (jCal) => {
  if (!jCal || !Array.isArray(jCal)) {
    console.error("Invalid jCal data");
    return "";
  }
  // Ensure the root component is VCALENDAR
  let comp;
  if (jCal[0] === "vcalendar") {
    // jCal represents a complete calendar object
    comp = new ICAL.Component(jCal);
  } else {
    // jCal represents an individual event (VEVENT), wrap it in a VCALENDAR component
    comp = new ICAL.Component(["vcalendar", [], []]);
    comp.addSubcomponent(new ICAL.Component(jCal));
  }
  // Convert the component to iCalendar format string
  const icsContent = comp.toString();
  return icsContent;
};

export const downloadIcsFileFromjCal = (jCal, eventSummary) => {
  // Logic for downloading the .ics file
  const icsContent = convertToIcs(jCal);
  const blob = new Blob([icsContent], { type: "text/calendar" });
  const url = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");
  link.href = url;

  // Set the download attribute with a filename
  const eventName = eventSummary ? eventSummary[3] : "event";
  link.download = `${eventName}.ics`;
  const keydownEvent = (e) => {
    if (e.key === "d") {
      const event = new MouseEvent("click", {});
      link.dispatchEvent(event);
    }
  };
  document.addEventListener("keydown", keydownEvent, { once: true });

  // Programmatically click the link to trigger the download by an event
  document.body.appendChild(link);
  const event = new KeyboardEvent("keydown", { key: "d" });
  document.dispatchEvent(event);

  // Remove the link from the document
  document.body.removeChild(link);

  // Release the object URL
  URL.revokeObjectURL(url);
};

export const fetchAndCacheImage = async (imageUrl, setImageUri) => {
  try {
    const response = await fetch(imageUrl, {
      cache: "force-cache",
    });
    if (response.ok) {
      const blob = await response.blob();
      const uri = URL.createObjectURL(blob);
      setImageUri(uri);
      return uri;
    }
  } catch (error) {
    console.error(error);
  }
};

export const copyCalendarLinkToClipboard = ({
  inGroup,
  user,
  calendar,
  showSnackBar,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarSeverity,
}) => {
  let calendarLink;
  if (inGroup) {
    calendarLink = `${
      user.createdGroups.find((group) => group.calendars.includes(calendar._id))
        .stanzaLink
    }/${user?.id}.ics`;
  } else {
    calendarLink = `${calendar?.stanzaLink}/${user?.id}.ics`;
  }
  if (navigator.clipboard && "writeText" in navigator.clipboard) {
    navigator.clipboard
      .writeText(getWebcalFormat(calendarLink))
      .then(() => {
        showSnackBar(
          setSnackbarOpen,
          setSnackbarMessage,
          setSnackbarSeverity,
          "Calendar link copied successfully.",
          "success"
        );
        console.log("Link copied to clipboard!");
      })
      .catch((err) => {
        showSnackBar(
          setSnackbarOpen,
          setSnackbarMessage,
          setSnackbarSeverity,
          "There was an issue copying the link. Please try again.",
          "error"
        );
        console.error("Failed to copy: ", err);
      });
  } else {
    // Android browsers don't support clipboard API
    const input = document.createElement("input");
    input.value = getWebcalFormat(calendarLink);
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
    showSnackBar(
      setSnackbarOpen,
      setSnackbarMessage,
      setSnackbarSeverity,
      "Calendar link copied successfully.",
      "success"
    );
  }
};

export const handleOpenWebcal = async (url, calendar) => {
  if (navigator.userAgent.match(/android/i)) {
    // const urlTest = "webcal://backend.stanza-testing.com/api/calendar/webcal/nhl-sharks/539eb3823fadbda86f0a9354/65f0cb14d9053ef6850a9540.ics"
    // try open in google calendar app
    const link = document.createElement("a");
    const intentUrl = `https://calendar.google.com/calendar/render?cid=${encodeURIComponent(
      url
    )}`;
    link.href = intentUrl;
    link.click();
  } else if (navigator.userAgent.match(/(iPod|iPhone|iPad|Safari|Mac)/)) {
    const link = document.createElement("a");
    link.href = url;
    link.download = `${calendar?.name}.ics`;
    if (
      navigator.userAgent.match(/Mac/) &&
      !navigator.userAgent.match(/(iPod|iPhone|iPad)/)
    ) {
      link.target = "_blank";
    }
    const keydownEvent = (e) => {
      if (e.key === "d") {
        const event = new MouseEvent("click", {});
        link.dispatchEvent(event);
      }
    };
    document.addEventListener("keydown", keydownEvent, { once: true });

    // Programmatically click the link to trigger the download by an event
    document.body.appendChild(link);
    const event = new KeyboardEvent("keydown", { key: "d" });
    document.dispatchEvent(event);

    // Remove the link from the document
    document.body.removeChild(link);
  } else {
    window.open(url, "_blank");
  }
};

export const handleUnsubscribe = async ({
  user,
  calendar,
  unsubscribeGroup,
  createdGroups,
  removeFromGroup,
  unsubscribeCalendar,
  onUnsubscribed = () => {},
  showSnackBar,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarSeverity,
}) => {
  try {
    // Determine the type of unsubscription and perform the appropriate action
    switch (true) {
      case user?.addedCalendars?.includes(calendar._id):
        await unsubscribeCalendar({ calendarId: calendar._id });
        break;
      case user?.addedGroups?.includes(calendar._id):
        await unsubscribeGroup({ groupId: calendar._id });
        break;
      case createdGroups?.some((group) =>
        group.calendars.some((calendarObj) => calendarObj._id === calendar._id)
      ):
        await removeFromGroup({ calendarId: calendar._id });
        break;
      default:
        throw new Error("Calendar or group not found in user's subscriptions.");
    }

    // Show success message
    showSnackBar(
      setSnackbarOpen,
      setSnackbarMessage,
      setSnackbarSeverity,
      "Calendar removed successfully.",
      "success"
    );

    await onUnsubscribed();
  } catch (error) {
    console.error(error);
    showSnackBar(
      setSnackbarOpen,
      setSnackbarMessage,
      setSnackbarSeverity,
      error.message || "An error occurred. Please try again.",
      "error"
    );
  }
};

export function getMinuteTimestamp() {
  const now = new Date();
  return Math.floor(now.getTime() / (1000 * 60));
}
