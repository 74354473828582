import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ical from "ical.js";
import { getPageRoute, useGetCalendarsByHandle, useGetCalendarEvents } from "src/services";
import { AuthContext } from "../utils/AuthContext.js";
import { getPastEvents, getUpcomingEvents } from "../utils/Utils.js";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";

export const useTimelineContent = ({ handle, calendarId, refetch = true, isEmbedded = false, notFetchEvents = false }) => {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [calendars, setCalendars] = useState([]);
    const [events, setEvents] = useState([]);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [pastEvents, setPastEvents] = useState([]);
    const [description, setDescription] = useState([]);
    const [calendarCreator, setCalendarCreator] = useState();
    const [selectedCalendar, setSelectedCalendar] = useState(false);
    const [tags, setTags] = useState([]);
    const [initialLoad, setInitialLoad] = useState(true);
    const [isLoadingEvents, setIsLoadingEvents] = useState(true);
    const [calendarNotFound, setCalendarNotFound] = useState(false);
    const { setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, showSnackBar } = useSnackbarContext();

    const creatorView = calendarCreator?._id === user?._id;

    const navigateToHandle = () => {
        if (location.pathname === `/${handle}`) return;
        navigate(getPageRoute("calendar", "ALL_EVENTS", { handle }));
        window.location.reload();
    };

    const onSuccessGetCalendars = (data) => {
        const items = [...(data.creator.createdCalendars || []), ...(data.creator.createdGroups || [])]
        setCalendars(items);
        if (data && items.length > 0) {
            const selected = calendarId
                ? items.find((c) => c._id === calendarId)
                : items[0]; // set selected calendar as either the one associated with the :id in the URL or the first calendar (if url is just /handle)
            if (!selected && !isEmbedded) {
                navigateToHandle();
                setCalendarNotFound(true);
            } else if (!selected && isEmbedded) {
                setCalendarNotFound(true);
            } else {
                setSelectedData(data, selected);
            }
        } else {
            navigateToHandle();
            setCalendarNotFound(true);
        }
    };

    const setSelectedData = (data, selected) => {
        setSelectedCalendar(selected);
        setCalendarCreator(data.creator);
        setCalendarNotFound(false);
    };

    const onSuccessGetCalendarEvents = (data) => {
        // Parse webcal contents
        if (data) {
            try {
                const jcalData = ical.parse(data);
                const comp = new ical.Component(jcalData);

                const vevents = comp.getAllSubcomponents("vevent");
                const upcoming = getUpcomingEvents(vevents, 100);
                const past = getPastEvents(vevents, 25);
                // Update the state with the parsed events and tags
                setEvents(vevents);
                // Splitting and sort events into upcoming and past using helper functions (in Utils.js)
                setUpcomingEvents(upcoming)
                setPastEvents(past)
                setTags(selectedCalendar.tags);
                setDescription(selectedCalendar.description);
            } catch (error) {
                console.error(error);
                // ex: /disneyplus-soundtrack-1/625001efbb55c8e69805591d
                showSnackBar(setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, 'An error occurred loading the calendar.', 'error');
                setIsLoadingEvents(false);
            }
        }
        setIsLoadingEvents(false);
    };

    const { refetch: refetchCalendars, isLoading, data: dataCalendars, isError } = useGetCalendarsByHandle(
        { handle },
        {}
    );

    const {
        refetch: refetchCalendarEvents,
        data: dataCalendarEvents,
        isLoading: isLoadingCalendarEvents,
        isError: isErrorCalendarEvents,
    } = useGetCalendarEvents(
        {
            link: selectedCalendar?.stanzaLink?.replace("webcal://", "http://") || ''
        },
        {
            enabled: !!selectedCalendar?.stanzaLink,
            isOwnProfile: creatorView,
        }
    );

    useEffect(() => {
        if (dataCalendars) {
            onSuccessGetCalendars(dataCalendars);
        }
        setInitialLoad(false);
    }, [dataCalendars]);

    useEffect(() => {
        if (dataCalendarEvents && dataCalendars && !notFetchEvents) {
            onSuccessGetCalendarEvents(dataCalendarEvents)
        }
        if (isErrorCalendarEvents) {
            setIsLoadingEvents(false);
        }
    }, [dataCalendarEvents, isErrorCalendarEvents]);


    // on page load
    useEffect(() => {
        if (refetch) {
            refetchCalendars();
        }
    }, []);


    useEffect(() => {
        if (refetch) {
            if (selectedCalendar?.stanzaLink) refetchCalendarEvents();
        }
    }, [selectedCalendar]);


    return {
        dataCalendarEvents,
        calendars,
        events,
        upcomingEvents,
        pastEvents,
        calendarCreator,
        description,
        tags,
        isLoadingEvents,
        initialLoad,
        creatorView,
        selectedCalendar,
        setSelectedCalendar,
        refetchCalendars,
        refetchCalendarEvents,
        navigateToHandle,
        isLoading,
        invalidCreator: isError,
        isLoadingCalendarEvents,
        isErrorCalendarEvents,
        calendarNotFound,
    }
}
